@import "../../../bps/components/burgerNavTree/burgerNavTree.scss";

.burger-nav-tree {
  &__item {
    margin: 0;
    padding: 0;
    display: block;
    border-bottom: $textDarkestColor 1px solid;

    &--teaser {
      display: none;
    }

    &--icon {
      display: flex;
      align-items: baseline;

      // remove this after refactoring of burgermenu when menu items gets configurable
      .native-app & {
        display: none;
      }

      & > * {
        flex-grow: 1;
      }

      &::before {
        padding: 0 6px 0 0;
        font-size: 14px;
        font-weight: 600;
      }

      &-wishlist {
        margin-top: 20px;

        &::before {
          content: map-get($icons, heartUnfilled);
          padding: 0 4px 0 2px;
          font-size: 12px;
        }
      }

      &-service::before {
        content: map-get($icons, service);
      }
    }

    &--headline {
      padding: 10px 0 15px;
      border-bottom: 2px solid $textDarkestColor;
      font-weight: $fontWeightBold;

      // remove this after refactoring of burgermenu when menu items gets configurable
      &:first-child {
        .native-app & {
          display: none;
        }
      }
    }

    .native-app & {
      @media (min-width: $screen-mobile) {
        font-size: $fontSizeLarge;
      }
    }
  }

  &__link {
    padding: 20px 0;

    &--arrow {
      &::before {
        content: map-get($icons, arrowDown);
        font-size: 22px;
        transform: translateY(-50%) rotate(-90deg);
      }
    }

    @media (max-width: $screen-mobile-landscape-max) {
      font-size: $baseFontSize;
    }
  }

  &__back {
    color: $textBaseColor;
    font-size: $baseFontSize;
    padding: 15px 0 15px 20px;
    z-index: 1;
    top: 0;
    cursor: pointer;
    position: relative;

    &::before {
      content: map-get($icons, arrowRight);
    }
  }
}
