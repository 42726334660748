@import "../../../bps/components/counterInput/counterInput.scss";

.counter--haw.counter {
  $root: '.counter';
  transition: border-color .2s ease-in-out;

  &:hover {
    border-color: $buttonCounterHoverBorderColor;
  }

  #{$root}__input {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;

    @include iconFont(arrowDown, after) {
      font-size: 14px;
      margin: 2px 0 0 -2px;
    }
  }

  #{$root}__input--disabled {
    cursor: text;
    border: $buttonCounterBorderColor 1px solid;

    &::after {
      display: none;
    }

    #{$root}__input-text {
      margin-left: 0;
    }
  }

  #{$root}__input-text {
    margin-left: 2px;
  }

  #{$root}__quantity-box {
    position: absolute;
    top: -($quantityBoxHeight + 7px);
    right: -($quantityBoxWidth * .5 - $buttonCounterWidth * .5);
    margin: 0;
    z-index: 11;

    .sale-box-bar & {
      top: $buttonCounterHeight + 7px;

      @media (max-width: $screen-mobile-landscape-max) {
        top: auto;
        bottom: $buttonCounterHeight;
      }
    }
  }

  .product-box #{$root},
  .product-box-wide #{$root} {
    position: static;

    @at-root .product-box, .product-box-wide {
      &__buttons {
        position: relative;
      }
    }

    &__quantity-box {
      right: 0;
    }
  }

  @at-root
  .cart-page .counter,
  .account-orders-list .counter,
  .product-teaser-wide .counter,
  .product-order-position .counter,
  .sale-box .counter {
    &__quantity-box {
      left: -1px;

      @media (min-width: $screen-tablet-portrait) {
        left: -($quantityBoxWidth * .5 - $buttonCounterWidth * .5);
      }
    }
  }
}

.counter--bps.counter {
  $root: '.counter';

  #{$root}__button {
    background: $buttonPrimaryBackgroundColor;

    &:hover {
      background: $buttonPrimaryHoverBackgroundColor;
    }

    &--disabled {
      background: $grayLightColor !important;
    }

    &::before,
    &::after {
      height: 25px;
    }
  }
}
