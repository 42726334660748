@import '../../../bps/components/productBoxSlider/productBoxSlider.scss';

.product-box-slider {
  &__slider {
    .flags {
      margin: 0;
    }

    .product-box__product-flags {
      top: 5px;
      left: 0;
    }

    .flag--new {
      top: auto;

      &:first-child {
        top: -5px;
      }
    }
  }

  .carousel__container {
    padding-top: 11px;  // space for the icon of personalization-banner
  }
}

// Placeholder style
/* stylelint-disable-next-line */
product-box-slider,
[is="product-box-slider"] {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  overflow: hidden;

  > .product-box {
    width: auto;
    max-width: 289px;
    margin-right: 24px;
    min-width: 260px;
    padding: 0;

    .product-box__placeholder {
      .product-box__price-wrapper {
        @include componentPreloader();
      }

      .product-box__button-wishlist {
        display: none;
      }
    }
  }
}
