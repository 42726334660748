.header-meta-content {
  display: none;

  @media (min-width: $screen-tablet-portrait) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__links {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      > .html-block {
        width: 100%;
      }

      .html-block {
        color: $headerMetaContentColor;
        font-weight: $headerMetaContentFontWeight;
        text-decoration: $headerMetaContentTextDecoration;
        font-size: $headerMetaContentFontSize;
        line-height: 1.4;

        ul {
          @include container();
          display: flex;
          justify-items: center;
          justify-content: space-between;
          list-style: none;
          margin: 0 auto;

          li {
            padding: 0 10px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: $headerMetaContentColor;
            font-weight: $headerMetaContentFontWeight;
            text-decoration: $headerMetaContentTextDecoration;
            font-size: $headerMetaContentFontSize;
            min-height: 30px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }

        a,
        a:not(.no-link) {
          color: $headerMetaContentColor;
          font-weight: $headerMetaContentFontWeight;
          text-decoration: $headerMetaContentTextDecoration;
          font-size: $headerMetaContentFontSize;
          display: inline-block;
          position: relative;
          padding: 0;

          &:visited {
            color: $headerMetaContentColor;
          }

          &:hover {
            color: $headerMetaContentHoverColor;
            text-decoration: underline;
            text-decoration-color: $headerMetaContentHoverColor;
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }

    .icons,
    .sprites {
      display: flex !important;
      justify-content: center;
      align-items: center;

      .sprite {
        display: inline-block;
        margin: 2px 10px;
      }

      .icon {
        display: flex;
        margin: 0 0 0 10px;

        &:first-child {
          margin: 0;
        }

        &::before {
          color: $whiteColor;
          font-size: 14px;
        }

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}
