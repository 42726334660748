@import '../../../bps/components/searchAutosuggest/searchAutosuggest.scss';

.search-autosuggest {
  &__input {
    .input__wrapper {
      border-radius: 5px;
      height: 46px;
      background: $searchAutosuggestBackgroundColor;

      .input__field {
        font-size: 17px;
        font-weight: 300;
        border-bottom: none;
        padding: 0 55px 0 5px;

        &::placeholder {
          color: $grayColor;
          opacity: 1;
          font-size: 17px;
        }
      }
    }
  }

  &__suggestions-flyout {
    box-shadow: 0 4px 6px 0 rgb(0 0 0 / .25);
    padding: 0 10px 20px;
    overflow-y: auto;
    top: 55px;
    max-height: calc(100vh - 130px);
    z-index: 1150;

    .native-app & {
      padding: 0 15px 120px;
      box-shadow: none;
    }

    @media (min-width: $screen-mobile) {
      padding: 0 15px 20px;
    }
  }

  &__suggestions-name {
    margin: 0 5px 5px;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 5px;
    }
  }

  &__suggestions-list-wrapper {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    position: relative;

    @media (max-width: $screen-tablet-portrait) {
      min-height: 100vh;
      align-content: flex-start;
    }
  }

  &__suggestions-list--right {
    @media (min-width: $screen-desktop) {
      &::before {
        background: $textDarkestColor;
      }
    }
  }

  &__suggestion {
    padding: 12px 5px;
    font-size: $searchAutosuggestSuggestionFontSizeMobile;
    line-height: $searchAutosuggestSuggestionLineHeightMobile;

    @media (min-width: $screen-tablet-portrait) {
      padding: 10px 0 5px;
      font-size: $searchAutosuggestSuggestionFontSize;
      line-height: $searchAutosuggestSuggestionLineHeight;
    }

    &:hover,
    &--active {
      text-shadow: none;
    }

    img {
      display: inline-block;
      padding: 0;
      margin: -3px 10px -3px 0;
    }
  }

  .input__button-clear {
    background: transparent;
    box-shadow: none;
    position: absolute;
    right: 55px;
    top: 18px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    @include iconFont('close', after) {
      color: $grayColor;
      font-size: 10px;
      align-self: center;
    }
  }

  .search-autosuggest__input {
    width: 100%;
    position: relative;
    padding: 5px 10px;
  }

  .input__button {
    right: 12px;
    top: 0;
    padding: 0 22px 0 10px;
    margin: 0 5px 0 0;

    &::after {
      font-size: 28px;
      color: $brandPrimaryColor;
    }
  }
}
