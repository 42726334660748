.burger-menu {
  width: 100%;
  position: fixed;
  top: 140px;
  left: 0;
  height: calc(100% - 140px);
  transform: translateX(-510px);
  transition: transform 500ms;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  z-index: $indexBurgerMenu;
  background: $backgroundWhiteColor;

  @media (min-width: $screen-mobile-sm) {
    top: 120px;
    height: calc(100% - 120px);
  }

  @media (min-width: $screen-tablet-portrait) {
    top: 169px;
    left: 0;
    height: calc(100% - 169px);
    max-width: 425px;
  }

  @media (min-width: $screen-desktop) {
    top: 71px;
    left: 0;
    height: calc(100% - 71px);
  }

  .native-app & {
    z-index: $indexBurgerMenuNativeApp;
    top: 120px;
    height: 100%;
  }

  &--open {
    transform: translateX(0);

    .native-app & {
      max-height: calc(100% - 180px);
    }
  }

  &__close-button {
    display: block;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    padding: 15px;

    @include iconFont(close) {
      color: $textBaseColor;
      font-size: $fontSizeXXMedium;
    }

    // remove this after refactoring of burgermenu when menu items gets configurable
    .native-app & {
      display: none;
    }
  }

  &__loader {
    @include spinner();
    animation: fadeIn .5s;
    animation-delay: 20ms;
  }
}

// Placeholder style
/* stylelint-disable-next-line */
burger-menu,
[is="burger-menu"] {
  display: none;
}
