@import '../../../bps/components/serviceTeaser/serviceTeaser.scss';

.service-teaser {
  &::before {
    @include fontSize(40px);
  }

  &__headline {
    font-weight: $fontWeightRegular;
  }

  &__subline {
    font-weight: $fontWeightThin;
  }

  &__button {
    border: 0 !important;

    .button {
      @include fontSize($fontSizeFont);
      background: transparent;
      color: $textBaseColor;
      border-color: $textBaseColor;
      line-height: 48px;

      &--hover,
      &:hover {
        box-shadow: 0 0 0 1px $blackColor inset;
      }
    }
  }
}
