@use "sass:math";

.quantity-box {
  $root: &;
  $rows: 4;
  $cols: 3;
  width: $quantityBoxWidthMobile;
  display: flex;
  flex-flow: row wrap;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / .5);
  padding: 0;
  font-size: $baseFontSize;
  perspective: none;
  backface-visibility: hidden; // stylelint-disable-line plugin/no-unsupported-browser-features

  @media (min-width: $screen-desktop) {
    width: $quantityBoxWidth;
  }

  #{$root}__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: math.div($quantityBoxWidthMobile, $rows);
    height: math.div($quantityBoxHeightMobile, $cols);
    border-right: 1px solid $quantityBoxBorderColor;
    border-bottom: 1px solid $quantityBoxBorderColor;
    background-color: $quantityBoxItemBgColor;
    color: $quantityBoxItemColor;
    cursor: pointer;

    @media (min-width: $screen-desktop) {
      width: math.div($quantityBoxWidth, $rows);
      height: math.div($quantityBoxHeight, $cols);
    }

    &--other {
      width: 100%;
      background-color: $quantityBoxItemOtherBgColor;
      color: $quantityBoxItemOtherColor;
      border: none;
    }

    &:hover,
    &--current {
      background-color: $quantityBoxItemBgColorHover;
      color: $quantityBoxItemColorHover;
    }

    &--disabled {
      background-color: $quantityBoxItemDisabledBgColor;
      color: $quantityBoxItemDisabledColor;
      cursor: not-allowed;

      &:hover {
        background-color: $quantityBoxItemDisabledBgColor;
        color: $quantityBoxItemDisabledColor;
      }
    }
  }
}
