.header {
  $root: &;
  $transitionTime: .25s;
  $transitionDelay: .25s;
  $transition: $transitionTime cubic-bezier(.25, .46, .45, .94);
  position: relative;
  z-index: $indexHeader;

  &--hide {
    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &--logo {
    #{$root}__main {
      justify-content: center;
      align-items: center;
      min-height: 80px;

      @media (min-width: $screen-mobile-sm) {
        min-height: 60px;
      }

      @media (min-width: $screen-tablet-portrait) {
        min-height: 100px;
      }
    }

    #{$root}__logo {
      width: 100%;
    }

    #{$root}__logo-link {
      margin: 10px 0;
      top: 0;
      left: auto;
      right: auto;
      position: relative;
    }
  }

  &--search-active {
    z-index: $indexHeaderSearchActive;

    .native-app & {
      z-index: $indexAppNav;
    }
  }

  &--filters-open {
    z-index: 1;
  }

  &__top-bar {
    position: relative;
    z-index: 0;
    background: none;

    .header__sticky--active & {
      display: none;
    }
  }

  &__meta-menu {
    display: block;
  }

  &__sticky {
    box-shadow: 0 0 5px 1px rgb(0 0 0 / .5);
    background: var(--header-background);

    &--enabled,
    &--active {
      will-change: position;
      position: relative;
      width: 100%;
    }

    &--active {
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  &__main {
    @include container();
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
    flex-wrap: wrap;
    position: relative;

    @media (min-width: $screen-mobile-large) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      min-height: 75px;
      padding-left: 3px;
      padding-right: 20px;
    }

    @media (min-width: $screen-desktop-sm) {
      min-height: 90px;
      padding-left: 20px;
      justify-content: flex-start;

      .header__sticky--active & {
        min-height: 71px;
        padding-left: 3px;
        align-items: center;
      }
    }
  }

  &__logo-wrapper {
    display: flex;
    height: auto;
    text-align: center;

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        order: 2;
        padding-left: 23px;
      }
    }
  }

  &__logo-link {
    display: inline-block;
    z-index: 600;
    width: 150px;
    height: auto;
    position: absolute;
    left: -40px;
    right: 0;
    margin: auto;
    top: 30px;
    transition: width 200ms ease-in-out;

    @media (min-width: $screen-mobile-sm) {
      width: 180px;
      height: auto;
      top: 17px;
      left: -10px;
    }

    @media (min-width: $screen-tablet-portrait) {
      left: 0;
      top: 12px;
      width: 210px;
    }

    @media (min-width: $screen-desktop-sm) {
      top: -7px;
      width: 250px;
      position: relative;
      margin: 26px 68px 0 0;
    }

    .header__main--type_checkout & {
      top: 6px;
    }

    .header__sticky--active & {
      @media (min-width: $screen-tablet-portrait) {
        position: absolute;
        float: none;
        margin: auto;
        width: 200px;
        left: 0;
      }

      @media (min-width: $screen-desktop-sm) {
        position: static;
        width: 200px;
        top: 12px;
      }
    }
  }

  &__logo {
    width: 90%;
    height: auto;
    display: block;
    margin: 0 auto;

    @media (min-width: $screen-mobile-large) {
      width: 100%;
    }
  }

  &__search-trigger,
  &__burger-menu-trigger {
    order: 1;
    font-size: 23px;
    display: block;
    padding: 0 15px;
    text-align: center;
    position: relative;

    .icon--mobileMenu::before {
      font-size: 29px;
    }
  }

  &__burger-menu-trigger {
    border: 0 none;
    background: transparent;
  }

  &__search-trigger {
    top: 3px;
    left: -5px;
    color: $whiteColor;
  }

  &__burger-menu {
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      order: 4;
    }

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }

    &--open,
    .burger-menu--open & {
      transition-duration: .1s;
      transition-delay: $iconBurgerDelay;
      background: transparent;

      &::before {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 0;
        transform: rotateZ(-45deg);
      }

      &::after {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 0;
        transform: rotateZ(45deg);
      }
    }
  }

  &__search-icon {
    @include iconFont(search);

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }
  }

  &__user-shortcuts {
    order: 3;
    position: relative;
    width: auto;
    margin: 0 5px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-grow: 1;

    @media (min-width: $screen-tablet-portrait) {
      margin: 10px 0 0;
      min-width: 260px;
    }

    @media (min-width: $screen-desktop-sm) {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
    }

    @media (min-width: $screen-desktop) {
      bottom: 10px;
      top: auto;
    }
  }

  &__user-shortcut {
    &--wishlist {
      display: none !important;

      @media (min-width: $screen-tablet-portrait) {
        display: block !important;
      }
    }
  }

  &__navigation {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      order: 4;
      display: block;
      width: 100%;
      margin: 7px 0 11px -10px;
      transition: opacity $transitionTime;
      transition-delay: $transitionDelay;
    }

    .header__sticky--active & {
      display: none;
    }

    .navigation__list > .navigation__element .navigation__link {
      color: $brandPrimaryColor;
      font-weight: $navigationLinkFontWeight;
    }
  }

  &__search {
    margin: 20px auto 5px;
    width: 100%;
    order: 5;
    display: block;
    position: relative;

    @media (min-width: $screen-mobile-sm) {
      margin: 12px auto 5px;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 70%;
      margin: 15px auto 10px;
    }

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 52%;
      position: relative;
      margin: 0;
      transition: width $transition, right $transition;
      transition-delay: $transitionDelay;
      order: 2;
      top: -5px;

      .header__sticky--active & {
        top: 0;
      }
    }

    .header__sticky--enabled.header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        bottom: 0;
        right: 0;
        position: relative;
        width: 52%;
        margin: 0 0 0 62px;
      }
    }

    .search-autosuggest__close {
      opacity: 0;
      transition: opacity .1s linear;
      transition-delay: $transitionTime + $transitionDelay;

      .header__sticky--active & {
        display: none;
      }
    }

    &.search-autosuggest--focus {
      .search-autosuggest__close {
        @media (min-width: $screen-desktop-sm) {
          @include iconFont(close);
          position: absolute;
          right: -43%;
          top: 40%;
          transform: translateY(-50%);
          cursor: pointer;
          opacity: 1;
          color: $whiteColor;
        }
      }
    }
  }

  &__product-flyout {
    width: calc(100vw - 30px);
    max-width: 320px;
    position: absolute;
    background: $backgroundWhiteColor;
    border-radius: 5px;
    top: 100%;
    margin-top: 10px;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / .3);
    z-index: $indexBasketFlyout;
  }

  &__checkout-process-breadcrumbs-wrapper {
    position: relative;
  }

  &__checkout-process-breadcrumbs {
    order: 6;
  }

  // Placeholder style
  /* stylelint-disable-next-line */
  search-autosuggest {
    height: 56px;
  }
}
