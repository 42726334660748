@import '../../../bps/components/servicesListTeaser/servicesListTeaser.scss';

.services-list-teaser {
  $root: &;

  &--size {
    &_1 {
      #{$root}__service {
        width: 100%;

        @media (min-width: $screen-tablet-portrait) {
          max-width: 250px;
        }
      }
    }

    &_2 {
      #{$root}__service {
        width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          max-width: 250px;
        }
      }
    }

    &_3 {
      #{$root}__service {
        width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: calc(100% / 3);
          max-width: 250px;
        }
      }
    }

    &_4 {
      #{$root}__service {
        width: 50%;

        @media (min-width: $screen-tablet-portrait) {
          width: 25%;
          max-width: 250px;
        }
      }

      .teaser-in-column & #{$root}__service {
        @media (min-width: $screen-desktop) {
          width: 50%;

          &:nth-child(even) {
            border-right: none;
          }
        }
      }
    }
  }
}
