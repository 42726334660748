@import "../../../bps/components/navigationFlyout/navigationFlyout.scss";

.navigation-flyout {
  @media (min-width: $screen-desktop-sm) {
    &__categories-element--teaser-center {
      padding: 0 10px;
    }

    .image-text-action-teaser--flyout {
      .image-text-action-teaser--default {
        padding: 10px 0 0;
      }

      .product-teaser > .product-teaser__link,
      .image-text-action-teaser__img-wrapper {
        width: 50%;
        padding: 0;
      }

      .product-teaser__box,
      .image-text-action-teaser__box {
        padding: 0 10px 0 20px;
      }

      .image-text-action-teaser__header {
        padding: 0;
        font-weight: $fontWeightSemiBold;
      }

      .image-text-action-teaser__subline {
        padding: 0;
      }

      .image-text-action-teaser__button {
        margin: 0;
      }
    }

    &__categories-element--teaser img {
      margin: 0;
    }
  }

  &__categories-link--all {
    font-weight: $fontWeightSemiBold;
    text-transform: none;

    &:hover {
      text-decoration: none;
    }
  }
}
