.price-info {
  $root: &;

  a {
    color: $priceColor;
    text-decoration: underline;
  }

  &__current {
    white-space: nowrap;
    color: $priceColor;
    font-size: 1em;
    font-weight: $fontWeightBold;
    font-family: $fontPrimaryFamily;
    display: flex;
    justify-content: flex-start;
    justify-items: center;

    #{$root}--reduction & {
      color: $priceDiscountColor !important;
    }
  }

  &__currency {
    font-size: .6em;
    margin-right: .25em;
    align-self: flex-end;
  }

  &__value {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    letter-spacing: -1px;
  }

  &__value-cent {
    font-size: .65em;
    margin: .08em 0 0 .08em;
  }

  &__asterisk {
    font-size: .4em;
    position: relative;
    align-self: flex-start;
  }

  &__unit,
  &__unit-text,
  &__tax {
    font-size: 12px;
    color: $priceColor;
    font-weight: $fontWeightThin;
  }

  &__unit-text,
  &__unit-info {
    white-space: nowrap;
  }

  &__unit-price {
    font-size: 12px;
    display: inline-flex;

    span {
      white-space: nowrap;
    }
  }

  &__unit {
    padding: 6px 0 0;
    line-height: 1.2;
  }

  &__tax {
    padding: 5px 0 0;
  }

  &--price-line-through {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-items: flex-end;

    .price-info__current {
      color: $grayDarkColor !important;
      margin: 0 8px 0 0;
    }

    .price-info__unit {
      margin: 0 0 7px;
    }

    .price-info__value {
      position: relative;
      float: left;
      margin: 0 8px 0 0;

      &::after {
        content: "";
        height: 2px;
        width: calc(100% + 8px);
        background: $grayDarkColor;
        top: 50%;
        left: -4px;
        display: block;
        position: absolute;
        transform: rotate(-16deg);
      }
    }
  }

  &__old {
    color: $textDarkestColor;
    font-size: 14px;
    font-weight: $fontWeightSemiBold;
    margin: 0 0 6px;
  }

  &__old-value {
    white-space: nowrap;
    font-weight: $fontWeightSemiBold;
    position: relative;

    &-price {
      @include strikethrough(currentColor);
      position: relative;
    }
  }

  &__savedPercentage {
    color: $whiteColor;
    background: $redColor;
    font-size: 12px;
    padding: 4px 7px;
    margin: 0 0 0 12px;
    font-weight: $fontWeightSemiBold;
    align-self: center;

    @media (min-width: $screen-tablet-landscape) {
      font-size: 14px;
      padding: 6px 9px;
      margin: 6px 0 2px 12px;
    }
  }

  &--savings-max {
    @include fontSize(10px);
    padding: 2px 3px 4px 4px;
    margin: 0 0 0 12px;

    strong {
      @include fontSize(17px);
      line-height: 1.1;
    }

    div {
      @include fontSize(14px);
    }
  }
}
