@import '../../../bps/components/productBox/productBox.scss';

.product-box {
  $root: &;

  &__bottle-amount {
    white-space: nowrap;
    margin-right: 5px;
  }

  &__body {
    justify-content: space-between;

    #{$root}--type-horizontal & {
      margin: 0 0 12px;
    }

    #{$root}--type-vertical & {
      margin: 0 0 12px;
    }
  }

  &__segment-header {
    display: block;
    border: 0;
    padding-bottom: 0;
    text-decoration: none;
    min-height: 55px;
  }

  &__header-container {
    display: flex;
  }

  &__header-text {
    margin: 0 0 14px;
    padding-right: 28px;

    #{$root}--type-vertical & {
      min-height: 58px;
    }

    &--with-vintage {
      #{$root}--type-vertical & {
        min-height: 78px;
      }
    }
  }

  &__vintage {
    margin-bottom: 3px;
    font-size: 16px;
    font-weight: $fontWeightRegular;
  }

  &__name {
    font-size: 16px;
    font-weight: $fontWeightBold;
  }

  &__short-description {
    font-size: 16px;
    font-weight: $fontWeightRegular;
  }

  &__segment-main {
    #{$root}--type-horizontal & {
      text-align: left;
    }

    #{$root}--type-vertical & {
      padding-top: 0;
      max-width: 124px;

      @media (min-width: $screen-mobile) {
        max-width: 155px;
      }

      @media (min-width: $screen-tablet-portrait) {
        max-width: 132px;
      }

      .products-list-page & {
        @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-portrait-max) {
          max-width: 112px;
        }
      }
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
  }

  &__segment-image {
    #{$root}--type-vertical & {
      max-width: 125px;

      @media (min-width: $screen-mobile) {
        max-width: none;
        padding: 0 15px 0 0;
      }

      @media (min-width: $screen-desktop) {
        max-width: 125px;
        padding: 0;
      }
    }

    #{$root}--type-horizontal & {
      flex-grow: 1;
      flex-direction: column;
      margin-bottom: 24px;
      display: flex;
    }

    &.lazy-load {
      #{$root}--type-vertical & {
        min-height: 272px;

        @media (min-width: $screen-tablet-portrait) {
          min-height: 270px;
        }
      }
    }
  }

  &__image-container {
    flex-direction: column;
    text-decoration-color: transparent;

    #{$root}--type-vertical & {
      padding: 0;
      margin-top: 0;
      align-items: center;
      justify-content: flex-end;
    }

    #{$root}--type-horizontal & {
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__image {
    img {
      #{$root}--type-vertical & {
        max-width: 100px;
      }

      #{$root}--type-horizontal & {
        max-width: 270px;
      }
    }
  }

  &__conversion {
    width: 100%;

    #{$root}--type-vertical & {
      display: flex;
      flex-grow: 1;
      flex-wrap: wrap;
      align-content: flex-end;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__price-wrapper {
    #{$root}--type-vertical & {
      width: 100%;
      min-height: 172px;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 176px;
      }

      @media (min-width: $screen-tablet-landscape) {
        min-height: 159px;
      }
    }
  }

  .price-info {
    &__unit,
    &__unit .price-info__unit-price,
    &__unit-text,
    &__tax {
      font-size: 10px;
    }

    &__old {
      color: $textDarkestColor;
      text-align: left;
    }

    &__tax,
    &__unit {
      color: $textDarkestColor;
      text-align: left;
    }

    &__current {
      font-size: 34px;
    }

    &__currency {
      margin: 0 .25em 1px 0;
    }

    &__asterisk {
      font-size: .5em;
      top: auto;
      left: auto;
    }

    &__savedPercentage {
      font-size: 10px;
      margin: 0 0 0 12px;
      padding: 5px 7px 6px;

      @media (min-width: $screen-tablet-landscape) {
        margin: 9px 0 0 12px;
      }
    }
  }

  &#{$root}--type-horizontal {
    .price-info__unit {
      padding: 0;
      margin: 8px 0 0;
    }

    .price-info__current {
      @media (min-width: $screen-tablet-portrait) {
        flex-wrap: wrap;
      }

      @media (min-width: $screen-desktop) {
        flex-wrap: nowrap;
      }
    }
  }

  &__ratings {
    justify-content: normal;
    font-size: 15px;
    padding: 4px 0;

    #{$root}--type-horizontal & {
      margin: 0;
    }

    #{$root}--type-vertical & {
      margin-bottom: 7px;
      min-height: 29px;
    }

    .ratings__ratings-count {
      font-weight: $fontWeightSemiBold;
      padding: 0 0 0 4px;
    }

    .ratings__star {
      margin: 0 2px;
      cursor: default;
    }
  }

  &__tags {
    margin-top: 0;
    margin-bottom: 10px;
    position: relative;

    .tags {
      &__tag {
        font-size: 14px;
        padding: 0;
        margin: 0 0 6px;
        border: 0;
        background: transparent;
        color: $textDarkestColor;

        &:hover {
          border: 0;
          background: transparent;
          color: $textDarkestColor;
        }

        &.tag--bulk_price,
        &.tag--subscription,
        &.tag--rarity {
          width: auto;
          height: auto;
          background: $redDarkColor;
          color: $whiteColor;
          font-size: $fontSizeXSmall;
          border-radius: 0;
          line-height: 1;
          text-transform: none;
          padding: 3px 5px 5px;
          font-weight: $fontWeightBold;
        }

        &.tag--bulk_price,
        &.tag--subscription {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
            color: $whiteColor;
          }
        }

        &.tag--subscription {
          background: $blueDarkColor;
          font-weight: $fontWeightSemiBold;

          &:visited {
            color: $whiteColor;
          }
        }
      }
    }
  }

  &__price-wrapper--no-price {
    flex-grow: 1;
    align-self: flex-end;
    width: 100%;

    .product-box--type-horizontal & {
      flex-direction: column;
      width: 50%;
    }
  }

  &__rating-container {
    margin: 0 0 4px;
    z-index: 1;

    .product-box__product-flags {
      position: relative;
    }

    .flags {
      position: absolute;
      z-index: 1;
    }
  }

  &__tags-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .flags {
      margin: 0 0 4px;
      position: relative;
      align-self: flex-end;
    }

    .flag--stoerer {
      background: transparent;
      border-radius: 0;
      width: auto;
      height: auto;
      line-height: 1.3;
      padding: 0;
      display: inline-block;
      font-size: 20px;
      font-weight: $fontWeightBold;
      color: $goldColor;
      text-transform: uppercase;
      text-align: right;
      min-width: 140px;
    }
  }

  &__product-flags {
    &--hasSpecialFlags {
      position: relative;
      align-self: flex-start;
      max-height: 70px;

      .flags {
        position: relative;
      }
    }

    #{$root}--type-vertical & {
      top: 0;
      left: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &.lazy-load {
      min-height: 42px;
    }
  }

  &__counter {
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-right: 10px;

    @media (min-width: $screen-tablet-portrait) {
      min-width: 42px;
      width: 42px;
      height: 42px;
    }
  }

  &__button-wishlist {
    font-size: 18px;
    position: absolute;
    width: 48px;
    height: 48px;
    right: 0;
    text-decoration: none;
    cursor: pointer;
    top: 0;
    padding: 14px;
    display: flex;
    justify-content: flex-end;
  }

  &__button-to-cart {
    height: 50px;
    min-width: 64px;
    width: 100%;
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      height: 42px;
    }

    @media (min-width: $screen-desktop) {
      min-width: 80px;
    }

    &::after {
      font-size: 24px;
      transform: translate(1px, 6px);
    }

    .products-list-page & {
      @media (min-width: $screen-tablet-portrait) and (max-width: $screen-tablet-landscape) {
        min-width: 54px;
      }
    }
  }

  &__availability {
    #{$root}--type-horizontal & {
      margin-bottom: 4px;
      margin-top: -8px;
    }

    #{$root}--type-vertical & {
      margin: 8px 0 0;
      width: 100%;
    }

    .availability-info--label {
      font-size: $availabilityInfoFontMobile;
      text-transform: none;
      word-break: break-word;
      text-align: left;
      display: flex;

      @media (min-width: $screen-tablet-portrait) {
        font-size: 10px;
      }

      &::before {
        width: 8px;
        height: 8px;
        min-width: 8px;
        min-height: 8px;
        margin: 3px 3px 4px 0;
      }
    }
  }

  &__status {
    padding: 4px 12px;
    margin-bottom: 4px;

    &--basket {
      background: $successBasketColor;
      padding: 3px 6px;
      margin: 0 0 8px 12px;
      font-size: 14px;
      border-radius: 0;
      width: auto;
      line-height: 1.2;

      @media (min-width: $screen-desktop) {
        margin: 0 0 6px;
        width: 146px;
        z-index: 1;
      }

      &::before {
        display: none;
      }
    }

    &--unavailable {
      min-height: 42px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      background: #bebebe;
      color: $whiteColor;
      align-self: center;
      text-transform: $productBoxUnavailableTextTransform;
      font-weight: $fontWeightSemiBold;
      font-size: $fontSizeSmall;
      letter-spacing: 0;
      margin: 0;
      width: 100%;
    }

    &--successor {
      font-size: 15px;
      font-weight: $fontWeightSemiBold;
      min-height: 42px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      white-space: inherit;

      &::after {
        display: none;
      }
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: column;

    #{$root}--type-vertical & {
      width: 100%;
      align-self: flex-start;
    }

    #{$root}--type-horizontal & {
      align-self: flex-end;
      width: 50%;
    }
  }

  &__link {
    align-self: flex-start;
    margin-top: 15px;
    color: $textDarkestColor;
    text-decoration-color: $textDarkestColor;

    @media (min-width: $screen-desktop) {
      margin-top: 10px;
    }
  }

  &__link-empty {
    min-height: 22px;
  }

  &__personalization-banner {
    margin: -14px -14px 12px;
    z-index: 10;

    .product-box-slider & {
      margin: -11px -11px 12px;
    }

    .product-teaser--big & {
      margin: -16px -16px 12px;
    }
  }

  &__segment-review {
    padding: 10px 10px 12px;

    &__headline {
      color: $personalizationColor;
      font-weight: $fontWeightBold;
    }
  }

  &--personalization {
    #{$root}__button-wishlist {
      z-index: 30;

      &:hover,
      &::after,
      &::before {
        color: $whiteColor !important;
      }

      &::after {
        top: -7px;
        right: -6px;
        bottom: 0;
        left: 0;
      }

      &::before {
        margin: -2px -3px 0 0;
      }
    }
  }

  &__placeholder {
    min-height: 365px;

    .product-box__product-flags--hasSpecialFlags {
      min-height: 28px;
    }

    .product-box__producer {
      display: none;
    }
  }

  &--highlight {
    box-shadow: 0 4px 6px 0 #0008;
    border-width: 50px 8px 8px !important;
    border-image: url('../staticImages/highlight-article.jpg') 50 8 8 repeat stretch !important;
  }

  &__highlight-text {
    text-align: center;
    text-transform: uppercase;
    color: $whiteColor;
    font-weight: 800;
    font-size: $fontSizeMedium;
    position: absolute;
    top: -35px;
    left: 0;
    width: 100%;
  }
}

@media (min-width: $screen-tablet-portrait) and (max-width: 800px), (min-width: $screen-tablet-landscape) and (max-width: 1100px) {
  .products-list {
    .product-box--type-vertical {
      .product-box__image img {
        width: 100px;
        min-height: auto;
      }

      .price-info__current {
        font-size: 34px;
      }
    }
  }
}
