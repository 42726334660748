@import '../../../bps/components/pageOverlay/pageOverlay.scss';

.page-overlay {
  &--visible-transparent {
    top: 120px;
    z-index: $indexPageOverlayVisibleTransparent;

    @media (min-width: $screen-tablet-landscape) {
      top: 0;
    }
  }

  &--search {
    z-index: $indexPageOverlaySearch;

    @media (max-width: $screen-mobile-landscape-max) {
      background-color: $whiteColor;
    }

    @media (min-width: $screen-tablet-portrait) {
      visibility: hidden;
    }
  }
}
